// Customize bootstrap theme here

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");

// Include any default variable overrides here (though functions won't be available)

$font-family-sans-serif: "Source Sans Pro", system-ui, -apple-system, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$primary: #4f33c8;
$body-bg: #000;
$body-color: #fff;
$link-color: #e5d4ff;

// include full bootstrap theme after the default variable overrides are declared
@import "../node_modules/bootstrap/scss/bootstrap";

// Then add additional custom code here
